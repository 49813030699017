.pg_wrapper {
  .messages {
    @include swap_direction(margin, 1em 0 0 0);
    @include swap_direction(padding, 0);
    font-size: 1.2em;
    font-weight: bold;
    background-color: $color-white;
    line-height: 1.5;
    border-bottom: 0;
    .single-message {
      background-color: $color-white;
      border: 0;
      color: $color-red;
      font-size: 12px;
      font-weight: normal;
      @include swap_direction(padding, 5px 13px);
      a {
        border-bottom: none;
        color: $color-black;
        text-decoration: underline;
      }
    }
    &:empty {
      display: none;
    }
  }
  img {
    max-width: 100%;
  }
  #promo-panel {
    &.panel {
      .banner {
        margin-bottom: 1em;
      }
    }
  }
  #recommended-products-panel,
  .right .tab-switcher {
    display: none;
  }
  #offer-code-panel,
  #links-panel,
  #need-help-panel,
  #favorites-panel,
  #past-purchases-panel {
    border-bottom: 2px solid $color-grey-border;
  }
  #shipmethod-panel,
  #recommended-products-panel,
  #offer-code-panel,
  #links-panel,
  #favorites-panel,
  #need-help-panel {
    header {
      border-bottom: 1px solid $color-grey-border;
      display: block;
      overflow: auto;
    }
  }
  #need-help-panel {
    h4 {
      font-weight: bold;
      span.text {
        font-weight: normal;
      }
      a {
        text-decoration: underline;
      }
    }
    .phone,
    .chat,
    .email {
      @include swap_direction(padding, 5px 0);
    }
  }
  #links-panel {
    .links_list {
      .link {
        margin-bottom: 0.5em;
        text-decoration: underline;
      }
    }
  }
  #viewcart-panel {
    content {
      padding: 1em 0;
    }
    .cart-items {
      .cart-header {
        display: none;
      }
      .cart-item {
        display: block;
        border-bottom: 2px solid $color-grey-border;
        .sub_line {
          display: none;
        }
        .price {
          margin-top: 15px;
          width: 25%;
          &.column {
            width: 33%;
          }
        }
        .qty.column {
          margin-top: 15px;
          width: auto;
          margin-left: 5px;
          .qty-label {
            text-align: left;
          }
        }
        .thumb {
          width: 30%;
        }
        .desc {
          width: 70%;
        }
        .qty {
          width: 70%;
          text-align: $rdirection;
          border: 0;
          height: auto;
          form#cart {
            display: inline-block;
          }
        }
        .total.column {
          margin-top: 32px;
          width: 19%;
          float: right;
          min-height: 65px;
          position: relative;
          text-align: right;
          form#cart,
          a.remove-sample {
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
        .column {
          float: $ldirection;
        }
        a.remove-sample {
          float: left;
        }
      }
    }
  }
  .column.left {
    #order-summary-panel,
    #shipmethod-panel {
      header {
        float: $rdirection;
        border-bottom: 1px solid $color-grey-border;
        display: block;
        overflow: auto;
        @include swap_direction(margin, 0 13px 0 0);
        @include swap_direction(padding, 0.4em 0em);
      }
    }
    #shipmethod-panel {
      header {
        float: none;
        padding: 0.4em 1em;
      }
    }
  }
  #order-summary-panel {
    .label {
      width: 70%;
      text-align: $rdirection;
      float: $ldirection;
      clear: $ldirection;
    }
    .afterpay {
      @include swap_direction(margin, 20px 0 10px 0);
      width: 100%;
      img {
        vertical-align: middle;
      }
      .afterpay-learnmore {
        border: none;
        span {
          background: #{$black};
          border-radius: 50%;
          height: 15px;
          width: 15px;
          line-height: 1.4;
          text-align: center;
          color: #{$white};
          display: inline-block;
          text-transform: lowercase;
          cursor: pointer;
          padding-#{$ldirection}: 1px;
        }
      }
    }
    .value {
      width: 30%;
      text-align: $rdirection;
      float: $ldirection;
      font-weight: bold;
    }
  }
  #shipmethod-panel {
    .ship-method-group-label {
      input {
        display: inline;
        float: left;
        margin: 5px;
        width: auto;
        padding: 7px;
      }
      label {
        display: block;
        margin: 5px 0;
        width: 90%;
        float: left;
      }
      a.overlay-link {
        float: right;
        padding-left: 10px;
        font-style: italic;
        text-decoration: underline;
      }
    }
  }
  .viewcart-buttons-panel {
    border-bottom: 1px solid $color-grey-border !important;
    .adyen_checkout_button {
      .paypal-smart-button {
        margin-bottom: 10px;
        .zoid-outlet {
          //important provided here to override PayPal third party file changes.
          /* stylelint-disable-next-line declaration-no-important */
          width: 100% !important;
        }
      }
      .continue-checkout {
        display: block;
        line-height: 3.5;
        text-align: center;
        width: 100%;
      }
    }
    .continue-buttons {
      text-align: $rdirection;
      @include swap_direction(padding, 10px 0);
      @include swap_direction(margin, 0 0 10px 0);
      a {
        display: inline;
        @include swap_direction(padding, 0.5em 0.8em);
        background: $color-black;
        border-radius: 0;
        text-decoration: none;
        font-weight: bold;
        color: $color-white;
        text-transform: uppercase;
        &.paypal-checkout {
          margin-#{$rdirection}: 15px;
          @include swap_direction(padding, 0);
          width: 142px;
          height: 54px;
          float: $ldirection;
          background: $color-white;
          color: $color-black;
          position: relative;
          bottom: 5px;
          img {
            width: 100%;
          }
          .paypal-message {
            color: #ccc;
            display: inline-block;
            text-align: center;
            text-transform: none;
          }
          &.disabled {
            background: url(/media/images/checkout/pay-pal-grey.png) top right no-repeat;
            width: 158px;
            img {
              visibility: hidden;
            }
          }
        }
      }
    }
    .continue-shopping {
      display: block;
      clear: $rdirection;
      text-decoration: underline;
    }
  }
  .column.left,
  .column.right {
    float: none;
    width: 100%;
    padding: 0;
    section.panel {
      margin: 10px 0 0 0;
      border: 0;
      header {
        border-bottom: 1px solid $color-grey-border;
        display: block;
        overflow: auto;
        position: relative;
        background-color: #fff;
        margin: 0;
        font-weight: bold;
        @include swap_direction(padding, 0.4em 1em);
        &.viewcart-header {
          border-bottom: 1px solid $color-grey-border;
          overflow: auto;
          background-color: $color-white !important;
          @include swap_direction(padding, 0.4em 1em !important);
          text-transform: uppercase;
        }
        h2 {
          padding: 0;
          line-height: 1.5em;
          font-size: 1.2em;
          color: #000;
          font-weight: bold;
          margin-bottom: 0px;
          float: $ldirection;
          text-transform: uppercase;
        }
        #header-item-count {
          float: $ldirection;
          margin-#{$ldirection}: 0.5em;
          font-size: 1.2em;
          font-weight: normal;
          color: $color-black;
        }
        a.edit.btn-mini {
          position: absolute;
          margin: 0;
          top: 0em;
          $rdirection: 0.6em;
          line-height: 26px;
          right: 0.6em;
        }
      }
      .content {
        overflow: hidden;
        word-wrap: break-word;
        clear: both;
        @include swap_direction(padding, 1em);
      }
      div.panel-group {
        margin: 1em;
        overflow: hidden;
        header {
          margin-bottom: 1em;
          border-bottom: 1px solid $color-grey-border;
          display: block;
          overflow: auto;
          padding: 0.2em 0;
          h3 {
            margin: 0 0 0.3em;
            color: #000;
            padding: 0;
            font-size: 1.2em;
            font-weight: bold;
            border-bottom-width: 0;
          }
        }
        .column {
          padding: 0.5em 1em;
          float: $ldirection;
        }
        #shipping-address-display.column {
          padding-right: 15px;
          width: 50%;
          border-right: 1px solid $color-grey-border;
        }
        #gift-options-display {
          &.column {
            clear: none;
            margin-#{$ldirection}: 15px;
            width: 41%;
          }
          &.gift-display {
            width: 100%;
            .panel {
              &-left {
                clear: none;
                float: #{$ldirection};
                width: 41%;
              }
              &-right {
                clear: none;
                float: #{$ldirection};
                width: 53%;
              }
            }
          }
        }
      }
      p {
        margin-bottom: 1em;
        &.field-are-required {
          float: $rdirection;
        }
      }
      &#review-panel {
        margin: 0 0 0 0;
        .form-container {
          float: $ldirection;
          width: 100%;
          .chckt-sdk {
            .chckt-pm {
              border: none;
              &__details {
                padding-#{$ldirection}: 0;
                .chckt-input-field {
                  border-radius: 0;
                  border-color: #{$color-black};
                  color: #{$color-black};
                  height: 40px;
                  &--cvc {
                    max-width: 92%;
                  }
                  &--error {
                    color: #{$color-red};
                    border: 2px solid #{$color-red};
                  }
                  &--recurring {
                    background: #{$color-white};
                    border: 1px solid #{$color-black};
                    border-radius: 0;
                    height: 40px;
                    padding: 12px 10px;
                  }
                }
              }
              &__header {
                padding-#{$ldirection}: 0;
                .chckt-pm__radio-button {
                  display: none;
                }
              }
              &__image {
                #{$rdirection}: 2px;
                margin: 0;
              }
              &__name {
                color: #{$color-black};
              }
            }
            .chckt-form--max-width {
              color: #{$color-black};
              max-width: 312px;
              min-width: 274px;
              .chckt-form-label {
                &__text {
                  color: #{$color-gray};
                  &--dark {
                    #{$rdirection}: 6px;
                    padding: 0 6px;
                    text-transform: initial;
                  }
                }
                &--exp-date {
                  min-width: 122px;
                  width: 45%;
                }
                &--cvc {
                  float: #{$ldirection};
                  max-width: 150px;
                  min-width: 108px;
                  padding-#{$ldirection}: 12px;
                }
                &__error-text {
                  color: #{$color-red};
                  text-transform: none;
                }
              }
            }
            .chckt-button-container {
              width: 89%;
              .chckt-more-pm-button {
                border: none;
                padding-#{$ldirection}: 0;
                &__icon {
                  display: none;
                }
                &__text {
                  color: #{$color-black};
                  font-size: 11px;
                  font-weight: bold;
                  letter-spacing: 1px;
                  margin-#{$ldirection}: 0;
                  text-decoration: underline;
                }
              }
              .chckt-button {
                background: #{$color-black};
                height: 36px;
                margin-bottom: 10px;
                padding: 10px;
                &--disabled {
                  opacity: 1.7;
                }
              }
            }
          }
          .payment_form {
            width: 100%;
            .payment-container {
              border-top: 1px solid #{$color-black};
              width: 100%;
              .payment-option {
                border-bottom: 1px solid #{$color-black};
                min-height: 50px;
                width: 100%;
                padding: 10px;
                .payment_options-terms,
                .adyen-payment {
                  display: none;
                }
                .paypal-terms {
                  .local-submits {
                    display: block;
                    .pc_hidden_adyen {
                      display: none;
                    }
                    .mobile_hidden {
                      display: block;
                    }
                    .paypal-express-btn {
                      background: #{$color-black};
                      color: #{$color-white};
                      padding: 5px 40px;
                    }
                  }
                }
                input[type='radio'] {
                  &:checked {
                    & ~ label {
                      .adyen-payment,
                      .payment_options-terms {
                        display: block;
                      }
                    }
                  }
                }
                .clearpay-button {
                  margin-bottom: 9px;
                }
                .related-media {
                  display: inline-block;
                  padding: 15px;
                  vertical-align: baseline;
                  &.cc {
                    padding: 1px;
                    .card-img {
                      margin-#{$ldirection}: 5px;
                      width: 27px;
                    }
                  }
                  &.pp {
                    height: 15px;
                    padding: 0 3px;
                    width: 60px;
                  }
                  &.cp {
                    height: 22px;
                    padding: 0 5px;
                    width: 91px;
                  }
                }
                .payment-terms {
                  clear: both;
                  .overlay-wrapper {
                    display: inline;
                  }
                }
                .afterpay-button {
                  height: 35px;
                  padding: 0 32px;
                }
              }
            }
            .local-button {
              display: none;
            }
          }
        }
        .form-item {
          margin-top: 1em;
          input[type='radio'] {
            @include swap_direction(margin, 0 0.5em 0 1em);
            text-indent: 0;
            display: inline-block;
            width: 16px;
            height: 16px;
            vertical-align: top;
            &:first-child {
              margin-#{$ldirection}: 0;
            }
          }
          label {
            display: inline-block;
            margin-bottom: 0.5em;
          }
          .card-expiry {
            width: 45%;
            clear: none;
            float: #{$ldirection};
            label {
              margin: 0;
              float: #{$ldirection};
            }
          }
          .section-separation {
            width: 10%;
            float: #{$ldirection};
            margin-top: 22px;
            text-align: center;
          }
          span.payment-option {
            float: $ldirection;
            display: inline-block;
            .related-media {
              padding: 10px 15px;
              display: block;
              img {
                margin-#{$rdirection}: 10px;
              }
            }
          }
        }
        .sub-section {
          margin-#{$ldirection}: 0em;
          margin-top: 10px;
          float: $ldirection;
          a.change-address {
            display: inline;
          }
          .select-address-part {
            margin: 19px 15px;
          }
        }
      }
      div.form-item {
        margin: 1.5em 0;
        width: 100%;
        float: none;
        clear: both;
        padding: 0;
        label {
          width: 90%;
          font-weight: bold;
          display: block;
          .afterpay-learnmore {
            position: absolute;
            margin-#{$ldirection}: 5px;
            border: none;
            span {
              background: #{$black};
              border-radius: 50%;
              height: 14px;
              width: 14px;
              line-height: 1.2;
              text-align: center;
              color: #{$white};
              display: inline-block;
              text-transform: lowercase;
              cursor: pointer;
              margin-#{$ldirection}: 0;
            }
          }
        }
        &.card-expiry-date {
          float: left;
          margin: 0 0 1.5em 0;
        }
        &.creditcard-option {
          @include swap_direction(padding, 27px 0 8px 0);
          border-top: 1px solid #{$color-light-gray};
        }
        &.paypal-option {
          @include swap_direction(padding, 25px 0 20px 0);
          border-top: 1px solid #{$color-light-gray};
          border-bottom: 1px solid #{$color-light-gray};
          .paypal-image {
            vertical-align: top;
          }
        }
        &.afterpay-option-box {
          @include swap_direction(padding, 15px 0 20px 0);
          border-bottom: 1px solid #{$color-light-gray};
          .afterpay-img {
            @include swap_direction(margin, 0 3px 1px 3px);
            vertical-align: top;
            max-width: 94px;
          }
          .checkbox-label {
            line-height: 1.6;
          }
          .afterpay {
            @include swap_direction(margin, 16px 1px 0 0);
            display: block;
            width: 90%;
            font-weight: normal;
          }
          .afterpay-disclaimer {
            @include swap_direction(margin, 13px 1px 0 0);
            display: block;
            line-height: 1.4;
            .purchase-agreement {
              color: #{$black};
              text-decoration: underline;
            }
          }
        }
        input[type='checkbox'] {
          text-indent: 0;
          display: inline-block;
          width: 18px;
          outline: none;
          height: 18px;
          float: $ldirection;
          & ~ label,
          & ~ .label {
            display: inline;
          }
        }
        input {
          width: 100%;
          font-size: 11px;
          padding: 0 0 0 5px;
          line-height: 25px;
        }
        select,
        select.field {
          width: 100%;
        }
        .nojs_hidden {
          select {
            width: 100%;
            margin: 0;
            float: $ldirection;
          }
          span {
            display: block;
            float: $ldirection;
            width: 6%;
            text-align: center;
            line-height: 30px;
          }
        }
      }
      section.divide {
        padding-top: 1em;
        border-top: 1px solid $color-grey-border;
      }
    }
    h3 {
      clear: none;
      margin: 0 0 1em;
      padding: 0;
      color: #000;
      font-size: 1.2em;
      font-weight: bold;
      border-bottom-width: 0;
      a {
        display: block;
        line-height: 1.5;
      }
    }
    #shipping-panel.substate-select {
      a.edit.btn-mini {
        top: 0.3em;
      }
      .ship_group_1 {
        float: $ldirection;
        input[type='radio'] {
          margin: 0em 0.5em 0.5em 0.5em;
          width: 16px;
          height: 16px;
          float: $ldirection;
          &:first-child {
            margin-#{$ldirection}: 0;
          }
        }
        input[type='radio'] ~ label {
          display: inline;
        }
      }
    }
  }
  .column.right {
    #shipmethod-panel {
      border-bottom: 2px solid $color-grey-border;
      display: block;
      overflow: auto;
      .ship-method-group-label {
        padding: 10px 0;
        width: 100%;
        overflow: hidden;
        float: $ldirection;
        margin-top: 5px;
        input[type='radio'] {
          padding-bottom: 7px;
          width: 4%;
          margin: 0px 5px 0px 0px;
          display: block;
        }
        label {
          display: block;
          vertical-align: middle;
          float: $ldirection;
          padding-bottom: 10px;
          margin: 0px 0px 3px 0px;
          width: 92%;
        }
      }
      .ship-method-group {
        clear: both;
        padding-top: 5px;
        text-align: $ldirection;
        width: 100%;
        overflow: hidden;
        select {
          float: none;
          font-size: 12px;
          padding: 3px 24px 3px 5px;
        }
      }
    }
    .afterpay {
      display: none;
    }
  }
  .mobile-breadcrumb {
    display: none;
  }
  #offer-code-panel {
    form#offer_code {
      overflow: hidden;
      input[type='text'] {
        width: 73%;
        margin-#{$rdirection}: 2%;
        float: $ldirection;
        height: 30px;
      }
      input.form-submit {
        clear: $rdirection;
        letter-spacing: normal;
        font-size: 12px;
      }
    }
  }
}

// !important is used below to override the inline styles from 3rd party content
.afterpay-paragraph {
  display: inline-block;
  .afterpay-link {
    background: $color-black;
    width: 15px;
    height: 15px;
    text-decoration: none !important;
    color: $color-white !important;
    vertical-align: text-bottom;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    line-height: 1.4;
    margin-#{$ldirection}: 6px;
  }
}

#checkout_review {
  div.submit {
    .afterpay-button {
      min-width: 267px;
      text-align: #{$ldirection};
      background: url(/media/images/checkout/cp-logo-white-transparent.svg) #{$black} no-repeat 86% 50%;
      height: 35px;
    }
  }
}

#viewcart {
  .donation_info_panel {
    width: 100%;
    padding: 3% 0%;
    .donation_message_mobile {
      display: block;
      margin: 0px 0px 0px 140px;
      font-size: 12px;
    }
    .donation_message {
      display: none;
      margin: 0px;
      font-size: 14px;
    }
    .donation_logo_mobile {
      display: block;
      float: left;
      height: 100%;
      padding-right: 20px;
    }
    .donation_logo {
      display: none;
    }
  }
  .donation_amounts_panel {
    label {
      font-size: 12px;
      background-color: #eff0f0;
    }
    span#select-amount {
      display: none;
    }
    #select-amount-mobile {
      font-weight: bold;
      font-size: 12px;
      display: inline;
      float: left;
    }
    span {
      padding: 0% 2% 0% 2%;
      white-space: nowrap;
    }
    h2.donation-panel__title {
      text-align: left;
      border-bottom: none;
      margin-bottom: 0px;
      background-color: #eff0f0;
      letter-spacing: 0.01em;
      margin: 3% 0%;
      padding: 3% 0 3% 0;
    }
    span.donation-group {
      input {
        vertical-align: middle;
      }
    }
  }
  donation_amounts_panel {
    #select-amount-mobile {
      float: left;
    }
  }
  #viewcart-panel {
    .cart-items {
      .product.donation {
        .total {
          a.remove-donation {
            float: left;
            position: static;
            margin-top: 0px;
            padding: 0% 0% 0% 6%;
          }
        }
      }
    }
  }
}

#confirm {
  #confirmation-panel {
    h2.checkout__subtitle {
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.1em;
      padding-top: 3px;
      border-bottom-style: none;
    }
    .confirmation-panel__confirm-text--donation {
      margin: 0px 0px 20px 0px;
      background-color: #f0f0f0;
      padding: 5% 0% 1% 5%;
    }
    .actions {
      padding: 1em;
    }
  }
}

.sign-in-panel {
  #new-or-returning-radios {
    margin-bottom: 1em;
    padding: 1em 0.5em 1em 1em;
    fieldset {
      float: left;
      width: 50%;
      clear: none;
    }
    input,
    label {
      display: inline-block;
      width: 15px;
      height: 15px;
      margin: 0px;
      padding: 0;
      vertical-align: middle;
    }
    label {
      display: inline;
    }
  }
  #new-account {
    width: 100%;
    margin-right: 0;
    padding: 0;
  }
  #return-user {
    width: 100%;
    margin-left: 0;
    clear: none;
    padding: 0;
  }
}

#samples-panel {
  margin: 1em 0;
  #content {
    padding-top: 0px !important;
    padding-bottom: 50px;
  }
  h3.samples-head {
    border-bottom: 1px solid #8c8c8d;
    border-top: 1px solid #8c8c8d;
    padding: 10px 13px 10px;
    font-size: 14px;
    color: #005288;
    text-transform: uppercase;
    margin: 0;
    font-weight: bold;
  }
  .remaining {
    p {
      margin: 15px 0;
    }
  }
  .sample-products {
    li {
      width: 100%;
      &.deactivate {
        .product-img {
          opacity: 0.4;
        }
      }
      .product-img {
        float: left;
        img {
          width: auto;
        }
      }
      .details {
        width: 65%;
        margin-left: 108px;
        ul.skus {
          li {
            border: none !important;
            min-height: 0px !important;
          }
        }
      }
      &.deactivate {
        a.sample-select-button {
          background-color: $color-grey;
        }
      }
    }
  }
  #sample-controls {
    .add-button {
      display: none;
    }
  }
}

#viewcart,
#index {
  .pg_wrapper {
    @include swap_direction(padding, 0 2% 0 2%);
  }
}

.pg_wrapper.samples-page,
#confirmation-page.pg_wrapper {
  @include swap_direction(padding, 0 2% 0 2%);
}

#viewcart {
  .column.left {
    section.panel {
      header {
        #header-item-count {
          float: $rdirection;
        }
      }
    }
  }
}

#cboxContent {
  #cboxLoadedContent {
    .site-header,
    .site-footer {
      display: none;
    }
    .site-content {
      padding-top: 10px;
    }
  }
}

#security-code-panel {
  margin: 0;
  padding: 5em 2em;
}

.gnav-util {
  &--cart {
    .cart-block {
      &__items {
        .cart-item {
          &__info {
            width: 100px;
          }
        }
      }
    }
  }
}

/*** ApplePay integration ***/

/* User not logged in */

.viewcart-buttons-panel {
  &.applepay {
    .pg_wrapper & {
      .applepay-integration-wrapper {
        div.applepay-button-container {
          .divide {
            border: 0 none;
            padding-top: 0;
          }
        }
      }
      .continue-shopping {
        clear: none;
      }
    }
    &.panel {
      .content {
      }
    }
    .continue-buttons {
      .continue-checkout,
      .paypal-checkout {
        display: inline-block;
      }
      .continue-checkout {
        float: right;
      }
      .or {
        float: right;
        display: inline;
        margin-top: 12px !important;
      }
      &.applepay-enabled {
        float: right;
        position: relative;
        width: 157px;
        height: 120px;
        padding: 0 !important;
        margin-bottom: 30px;
        text-align: center;
        .continue-checkout,
        .paypal-checkout {
          position: absolute;
          display: block !important;
          float: none;
          width: 100%;
          margin: 0;
        }
        .continue-checkout {
          top: 0;
        }
        .paypal-checkout {
          bottom: 0;
        }
        .or {
          position: absolute;
          top: 27px;
          float: none;
          display: block;
          width: 100%;
          text-align: center;
          margin: 12px 0 !important;
        }
      }
    }
    .applepay-integration-wrapper {
      float: right;
      width: 100%;
      padding: 10px 13px;
      border: 1px solid #b7b7b7;
      background: #ebebeb;
      &.applepay-error {
        border-color: #e61f26;
        background: #ffe2e4;
      }
      div.applepay-button-container {
        width: 65%;
        .divide {
          position: relative;
          padding-top: 0;
          padding-right: 45px;
          border: 0 none;
          input[type='checkbox'] {
            position: absolute;
            top: calc(50% - 4px);
            right: 10px;
            width: 15px;
            height: 15px;
          }
          .label-content {
            a {
              display: inline !important;
            }
          }
        }
      }
      span.applepay-button-container {
        width: 35%;
        text-align: center;
        .apple-pay-button {
          display: block !important;
        }
      }
      .applepay-button-container {
        display: inline-block;
        vertical-align: middle;
        text-align: justify;
        .apple-pay-button {
          overflow: hidden;
          text-indent: 100%;
          white-space: nowrap;
          -webkit-appearance: -apple-pay-button;
          -apple-pay-button-style: black;
        }
        .apple-pay-button.button-style-buy {
          -apple-pay-button-type: buy;
        }
        .apple-pay-button.button-style-set-up {
          -apple-pay-button-type: set-up;
        }
      }
      @media (max-width: 767px) {
        .continue-shopping {
          position: static;
          float: left;
          display: block;
          width: auto;
        }
      }
      @media (min-width: 40em) and (max-width: 641px) {
        span.applepay-button-container {
          display: block;
          float: left;
          padding-left: 0;
        }
      }
      @media (max-width: 40em) {
        .applepay-button-container {
          display: block;
          min-width: 100%;
          padding-left: 0;
          .btn {
            display: block;
            width: 100%;
          }
        }
      }
    }
    .js-applepay-error-container {
      color: #eb3473;
      padding: 10px 5px 0;
      float: left;
    }
  }
}
/* 640px */

@media (max-width: 40em) {
  .viewcart-buttons-panel {
    &.applepay {
      &.panel {
        .content {
          float: none;
        }
      }
      .continue-shopping {
        width: 100%;
        margin: 2em 0 1em !important;
        text-align: center;
      }
      .continue-buttons {
        &.applepay-enabled {
          width: 100%;
          height: 120px;
          .continue-checkout,
          .paypal-checkout {
            float: none !important;
            width: 100% !important;
            text-align: center;
          }
          .continue-checkout {
            width: 100%;
            margin-bottom: 7px !important;
            text-align: center;
            margin-top: 60px;
          }
          .paypal-checkout {
            img {
              width: auto;
            }
          }
          .or {
            position: absolute;
            top: 23px;
            width: 100% !important;
          }
        }
      }
      .applepay-integration-wrapper {
        display: table;
        table-layout: fixed;
        float: none;
        clear: both;
        width: 100%;
        margin: 0;
        section.divide {
          padding-top: 10px;
        }
        div.applepay-button-container {
          display: table-footer-group;
          float: none;
          width: auto;
          text-align: left;
          .label-content {
            margin-left: 0;
          }
          .error {
            margin: 5px 0 !important;
            background: transparent !important;
          }
        }
        span.applepay-button-container {
          display: table-header-group;
          float: none;
          width: auto;
        }
      }
    }
  }
}

.afterpay_modal_wrapper {
  .headline {
    line-height: 1;
  }
}
/* User logged in */

.elc-user-state-logged-in {
  &#viewcart {
    .viewcart-buttons-panel {
      &.applepay {
        .continue-shopping {
          margin: 0;
          width: 130px;
        }
        @media (max-width: 1024px) {
          .continue-shopping {
            position: static;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
  .viewcart-buttons-panel {
    &.applepay {
      .pg_wrapper & {
        .applepay-integration-wrapper {
          div.applepay-button-container {
            .divide {
              border: 0 none;
            }
          }
        }
      }
    }
  }
  .viewcart-buttons-panel {
    &.applepay {
      padding-left: 0;
      .continue-shopping {
        margin: 0;
        padding: 5px;
      }
      .continue-buttons {
        &.applepay-enabled {
          float: right;
          position: static;
          width: auto;
          height: auto;
          margin-bottom: 0;
          font-size: 0;
          line-height: 0;
          .or {
            display: none;
          }
          .paypal-checkout,
          .continue-checkout {
            position: static;
            display: inline-block !important;
            float: none;
            width: auto;
            vertical-align: top;
          }
          .paypal-checkout {
            width: 165px;
            height: auto;
            margin-right: 4px;
            img {
              height: inherit;
              margin-right: 0;
            }
          }
        }
      }
      .applepay-integration-wrapper {
        width: auto;
        background: transparent;
        border: 0 none;
        margin-right: 4px;
        padding: 0;
        div.applepay-button-container {
          display: none;
        }
        span.applepay-button-container {
          width: 140px;
          height: 35px;
        }
      }
      @media (max-width: 1024px) {
        padding-left: 10px;
        .continue-shopping {
          position: static;
          width: auto;
        }
        .continue-buttons {
          &.applepay-enabled {
            float: none;
            width: 170px;
            margin: auto;
            .paypal-checkout,
            .continue-checkout {
              display: block !important;
              margin: 0 auto 20px !important;
            }
            .continue-checkout {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
        .applepay-integration-wrapper {
          clear: both;
          margin: 0;
          float: none;
          text-align: center;
          span.applepay-button-container {
            width: 170px;
          }
        }
      }
      @media (max-width: 40em) {
        .continue-shopping {
          margin-bottom: 1em !important;
        }
        .js-applepay-error-container {
          float: none;
        }
        .applepay-integration-wrapper {
          display: block;
          float: none;
          clear: both;
          width: auto;
          margin: 0;
          section.divide {
            padding-top: 10px;
          }
          .applepay-button-container {
            .btn {
              width: 170px;
              height: 32px;
            }
          }
          div.applepay-button-container {
            display: none;
          }
          span.applepay-button-container {
            display: block;
            float: none;
            width: auto;
          }
        }
      }
      @media (max-width: 480px) {
        .continue-shopping {
          float: none;
          width: 100%;
          text-align: center;
        }
        .continue-buttons {
          &.applepay-enabled {
            float: none;
            width: auto;
            margin: 0 auto;
            .paypal-checkout,
            .continue-checkout {
              width: 135px !important;
              margin: 0 auto 20px !important;
            }
          }
        }
        .applepay-integration-wrapper {
          float: none;
          margin: auto;
          .applepay-button-container {
            .btn {
              margin: auto;
            }
          }
        }
      }
    }
  }
}
.paypal-overlay {
  background: #{$black};
  #{$ldirection}: 0;
  height: 100%;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
.paypal-processing {
  #{$ldirection}: 0;
  margin: 0 auto;
  padding: 10px;
  position: fixed;
  #{$rdirection}: 0;
  text-align: center;
  top: 25%;
  width: 100%;
  z-index: 999;
  .process-content {
    background: #{$white};
    max-width: 335px;
    margin: 0 auto;
    position: relative;
    padding: 30px 10px;
    z-index: 999;
  }
  .lab-logo {
    padding-bottom: 28px;
  }
}
/*** End of ApplePay integration ***/
