.password-field {
  &__info {
    @include swap_direction(margin, 4px auto);
    width: auto;
    @include breakpoint($portrait-up) {
      border: 1px solid #{$color-dark-gray};
      &::before {
        content: '';
        position: absolute;
        top: 23%;
        right: 90%;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #{$color-black} transparent;
      }
    }
    li {
      @include swap_direction(padding, 3px 0 3px 10px);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      color: #{$color-black};
      transition: 0.2s;
      white-space: nowrap;
      line-height: 1.5;
      font-size: 11px;
      letter-spacing: initial;
      &.pass {
        color: #{$color-green};
        &::before {
          color: #{$color-green};
          content: $tick-mark;
          text-shadow: 0 0 8px #{$color-black};
        }
      }
      &::before {
        @include swap_direction(margin, 0 6px 0 0);
        content: $cross-mark;
        display: inline-block;
        color: #{$color-red};
        font-size: 1em;
        line-height: 0;
        transition: 0.2s;
      }
    }
  }
  @include breakpoint($med-small-down) {
    &__rules {
      column-count: 2;
    }
  }
}
