/* NOTIFICATION OVERLAY
---------------------------------------------- */
#bt_notification {
  .bt-content {
    border: 10px solid $color-dark-blue;
    padding: 20px 40px 20px 20px;
    background-color: $color-white;
    z-index: 10001;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    @include breakpoint($portrait-up) {
      z-index: 9999; // GDPR - must be visible at all times
    }
    a.bt-close-link {
      position: absolute;
      top: 10px;
      right: 10px;
      display: block;
      width: 15px;
      height: 15px;
      background: url(/media/export/images/cookies/close_x_v2.png) 50% 50% no-repeat;
      text-indent: -9999em;
    }
    h3 {
      display: none;
      font-size: 1.8rem;
    }
    a {
      color: $color-dark-blue;
    }
    p {
      margin: 0;
    }
    h6 {
      font-size: 1.17em;
      margin-bottom: 5px;
    }
    .btn {
      display: block;
      text-align: center;
      margin-top: 10px;
      background: 0 0;
      border: 1px solid #000000;
      font-weight: 700;
      padding: 0 12px;
      text-transform: uppercase;
      text-decoration: none;
      line-height: 35px;
      white-space: nowrap;
      letter-spacing: normal;
    }
    .bt-pp-section {
      margin-bottom: 20px;
    }
  }
  .bt-bottom-right,
  .bt-bottom-full {
    position: fixed;
    bottom: 0px;
  }
  .bt-bottom-right {
    right: 10px;
    width: 310px;
  }
  .bt-bottom-full {
    right: 0;
  }
} // #bt-notification
/* COOKIE PRIVACY SETTINGS
---------------------------------------------- */

#bt_settings {
  border: 2px solid #9fa617;
  padding: 15px;
  @include swap_direction(margin, 20px 0);
  background-color: $color-white;
  z-index: 1001;
  font-size: 12px;
  line-height: 16px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  @include breakpoint($portrait-up) {
    padding: 2em;
    height: 100%;
    min-width: 580px;
  }
  .bt-intro {
    h2 {
      text-transform: none;
      color: #9fa617;
      @include breakpoint($portrait-up) {
        font-size: 20px;
      }
    }
    .bt-intro-copy {
      p {
        margin-top: 1em;
      }
    }
  }
  /* TOGGLE SLIDER */
  div.slide,
  ul.slide-toggle {
    position: relative;
    overflow: hidden;
    width: 246px;
    @include breakpoint($portrait-up) {
      width: 525px;
    }
  }
  div.slide {
    margin: 20px auto;
    @include breakpoint($portrait-up) {
      margin: 20px 0;
    }
  }
  ul.slide-toggle {
    background: transparent url('/media/images/cookies/m_all-off.png') 50% 100% no-repeat;
    background-size: contain;
    margin: 0;
    padding-left: 0px !important;
    @include breakpoint($portrait-up) {
      background-image: url('/media/images/cookies/all-off.png');
    }
    li {
      cursor: pointer;
      float: left;
      width: 82px;
      height: 136px;
      list-style: none;
      @include breakpoint($portrait-up) {
        width: 175px;
        height: 149px;
      }
      span {
        display: none;
        font-weight: bold;
        text-align: center;
        margin: 5px;
      }
      &.middle {
      }
      &.selected {
      }
    }
  }
  div.mask {
    position: absolute;
    overflow: hidden;
    top: 0px;
    left: 0px;
    width: 0px;
    border-right: 1px solid #eee;
    ul.slide-toggle {
      background-image: url('/media/images/cookies/m_all-on.png');
      @include breakpoint($portrait-up) {
        background-image: url('/media/images/cookies/all-on.png');
      }
    }
    div.drag {
      position: absolute;
      bottom: 11px;
      right: 30px;
      width: 26px;
      height: 11px;
      background: transparent url('/media/images/cookies/icon_arrow.png') 50% 100% no-repeat;
      @include breakpoint($portrait-up) {
        right: 70px;
        bottom: 12px;
      }
    }
  }
  /* PRIVACY INFORMATION */
  .bt-privacy-info {
    display: none;
    overflow: hidden;
    div {
      width: 100%;
      margin-right: 2%;
      float: none;
      @include breakpoint($portrait-up) {
        float: left;
        width: 48%;
      }
      ul {
        list-style-type: none;
        padding-left: 0 !important;
        margin: 1em;
        li {
          list-style-type: none;
          margin: 0.8em 0;
          padding-left: 30px;
          background-position: 0 50%;
          background-repeat: no-repeat;
        }
      }
    }
    &.selected {
      display: block;
      &[data-userprivacy='OPTED_IN'] div.bt-privacy-willnot {
        display: none;
      }
    }
    h3 {
      padding: 1em 1em 1em 40px;
      background-position: 10px 50%;
      background-repeat: no-repeat;
      background-color: #f9f9ef;
    }
    div.bt-privacy-will {
      h3 {
        background-image: url('/media/images/cookies/icon_safe_lock.png');
      }
      ul li {
        background-image: url('/media/images/cookies/icon_safe_thick.png');
      }
    }
    div.bt-privacy-willnot {
      h3 {
        background-image: url('/media/images/cookies/icon_unsafe_lock.png');
      }
      ul li {
        background-image: url('/media/images/cookies/icon_unsafe_thick.png');
      }
    }
    &-optedin {
      div.bt-privacy-willnot {
        display: none;
      }
    }
  } // .bt-privacy-info
} // #bt_settings

// hide un-rendered template content
.privacy-notification__template-content,
.privacy-settings__template-content,
.privacy-settings-content-placeholder {
  display: none;
}
/* Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  width: 100%;
  padding-bottom: 10px;
  &.profile-tool {
    .tooltiptext-over::before {
      left: initial !important;
      right: 17%;
    }
  }
  &--right {
    float: right;
    text-align: right;
    max-width: 300px;
    .tooltiptext-over::before {
      left: initial !important;
      right: 17%;
    }
  }
  &.photo-tip {
    padding-top: 5px;
  }
  a {
    text-decoration: none;
  }
  .tooltiptext-under,
  .tooltiptext-over {
    visibility: hidden;
    width: 100%;
    max-width: 400px;
    background-color: $color-white;
    color: $color_fuscous_gray;
    border: 1px solid $color-grey-scale;
    border-radius: 6px;
    padding: 10px !important;
    position: absolute;
    z-index: 1;
    left: 0%;
    opacity: 0;
    transition: opacity 0.3s;
    text-align: left;
  }
  .tooltiptext-under {
    top: 125%;
  }
  .tooltiptext-over {
    bottom: 125%;
  }
  .tooltiptext-under::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 17%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $color_fuscous_gray transparent;
  }
  .tooltiptext-over::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 17%;
    border-width: 5px;
    border-style: solid;
    border-color: $color_fuscous_gray transparent transparent transparent;
  }
  &:hover .tooltiptext-under,
  &:hover .tooltiptext-over {
    visibility: visible;
    opacity: 1;
  }
}

@include breakpoint($medium-up) {
  .site-header__content {
    overflow: visible !important;
  }
  #registration {
    #content {
      .submit_btn {
        clear: both;
      }
      .profile-tool,
      .privacy-policy-link {
        float: right;
        max-width: 300px;
        text-align: right;
        clear: both;
      }
    }
  }
}

.tooltip--waitlist {
  margin-top: 10px;
  padding: 0;
}
