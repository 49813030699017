$adaptive-placeholder: (
  height: 10em
);

body.section-customer-service-contact-us {
  .site-content {
    .cs-page.sidebar-page {
      .content-container {
        .cs-page__content-item {
          .cs-contact-forms-v1 {
            .single-col {
              #questions {
                .contact-questions {
                  form#contact_us {
                    .question-regarding {
                      .question_section.section_online_order {
                        .contact_online_order {
                          input#form--contact_us--field--ORDER_NUMBER.input_txtarea.order_number {
                            padding: 0 0 0 5px;
                            line-height: 0;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.adpl {
  textarea {
    @include adaptive-placeholder(
      (
        height: map-get($adaptive-placeholder, height),
        textarea: true
      )
    );
    padding-top: calc(1em - -1px);
    &:focus {
      border-color: $color-light-gray;
    }
  }
}

.egiftcard-container-mobile {
  margin: 0px auto;
  padding-top: 143px;
  overflow: hidden;
  width: 100%;
  .egift-card-image {
    margin-bottom: 15px;
  }
  .header-image {
    font-size: 36px;
    font-weight: bold;
    font-family: arial;
    margin-bottom: 20px;
    img {
      width: 100%;
    }
  }
  .giftcard-buttons {
    li {
      a {
        color: #2b2b2b;
        display: inline-block;
        margin-right: 5px;
        float: left;
      }
    }
  }
}

@media screen and (max-width: 767px) and (min-width: 320px) {
  .egiftcard-container-mobile {
    .inner-egiftcard-container {
      margin: 10px;
      .egift-card-image {
        text-align: center;
      }
      .header-image {
        font-size: 30px;
        margin-bottom: 10px;
      }
      .giftcard-buttons {
        margin: 0px;
        overflow: hidden;
        padding: 0px;
        li {
          a {
            display: block;
            float: none;
            padding: 5px 5px;
            text-transform: none;
          }
        }
      }
    }
  }
}
