#confirmation-page {
  .social-login {
    padding-#{$ldirection}: 12px;
    &__container {
      text-align: $ldirection;
      padding-bottom: 0;
      margin-#{$ldirection}: 0;
    }
    &__terms,
    &__divider {
      display: none;
    }
    &__title {
      display: block;
      font-weight: bold;
      font-size: 15px;
    }
    &__info {
      margin-bottom: 10px;
      display: block;
    }
    &__email-opt-in {
      padding-#{$ldirection}: 0;
      display: flex;
      padding-bottom: 10px;
      #facebook_email_opt_in {
        margin-#{$rdirection}: 10px;
        width: 20px;
        height: 20px;
        padding: 6px;
        @include breakpoint($medium-up) {
          width: auto;
          height: auto;
        }
      }
    }
    .disclaimer {
      display: flex;
      padding: 30px 0 10px;
      text-align: $ldirection;
      #fb-field-disclaimer {
        width: 20px;
        height: 20px;
        padding: 6px;
        margin-#{$rdirection}: 10px;
        @include breakpoint($medium-up) {
          width: auto;
          height: auto;
        }
      }
    }
    .fb_iframe_widget {
      padding-top: 10px;
    }
    .tooltip.disclaimer_text {
      padding-#{$ldirection}: 0;
      display: none;
    }
  }
}

.social-login {
  &__container {
    margin: 16px;
    @include breakpoint($medium-up) {
      margin: 0;
    }
  }
  &__terms {
    padding: 0;
  }
  .tooltip {
    text-align: $ldirection;
  }
}

#signin,
.sign-in-panel {
  .social-login {
    &__terms {
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 34px;
      }
    }
    .tooltip {
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 34px;
      }
    }
  }
  .social-login.gnav {
    .social-login__terms {
      padding: 0 20px;
    }
    .tooltip {
      padding-#{$ldirection}: 20px;
    }
  }
}

div.column {
  section.panel {
    input.social-login__opt-in-checkbox {
      width: auto;
    }
  }
}

.social-login.gnav {
  .social-login__terms {
    padding: 0 20px;
  }
  .tooltip {
    padding-#{$ldirection}: 20px;
  }
}
/* Account signin page */
.outer-wrap .social-login__container .disclaimer {
  text-align: #{$ldirection};
}
/* Checkout */
#main.single {
  .sign-in-panel input {
    width: auto;
    vertical-align: middle;
  }
  .panel label {
    display: inline;
  }
}

.fb-disclaimer-error {
  color: $color-red;
  padding-bottom: 10px;
}

.fb-overlay-container {
  width: 265px;
  height: 40px;
  position: relative;
  margin: 0 auto;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

.fb-overlay-social-info {
  display: inline-flex;
  width: 164px;
  height: 22px;
  position: relative;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}
/* Gnav Signin Block */
.gnav-utility-item {
  .signin-block__forms {
    .social-login.gnav {
      .fb-disclaimer-container {
        text-align: #{$ldirection};
        margin-top: 20px;
        #fb-field-disclaimer {
          margin-top: 5px;
        }
      }
      .social-login__email-opt-in {
        padding-top: 10px;
        label {
          padding-#{$rdirection}: 0;
        }
      }
    }
  }
}
/* Account Signin Page */
#signin {
  .social-login {
    .social-login__container {
      .disclaimer {
        text-align: #{$ldirection};
        padding: 20px 0 10px 0;
        display: flex;
        @include breakpoint($medium-up) {
          padding: 20px 0 10px 34px;
        }
        .field {
          width: 25px;
          margin-#{$rdirection}: 10px;
          @include breakpoint($medium-up) {
            width: auto;
          }
        }
      }
      .social-login__email-opt-in {
        margin: 15px 10px 10px 0;
        padding: 0;
        @include breakpoint($medium-up) {
          margin: 15px 10px 10px 34px;
        }
        #facebook_email_opt_in {
          margin-#{$rdirection}: 5px;
        }
      }
    }
  }
}
/* Checkout Signin Page */
#sign-in-panel {
  .social-login {
    .social-login__container {
      .disclaimer {
        display: flex;
        padding: 25px 0 10px;
        text-align: #{$ldirection};
        @include breakpoint($medium-up) {
          padding-#{$ldirection}: 13px;
        }
        #fb-field-disclaimer {
          width: 20px;
          height: 20px;
          padding: 6px;
          margin-#{$rdirection}: 10px;
          @include breakpoint($medium-up) {
            width: auto;
            height: auto;
          }
        }
      }
      .tooltip {
        @include breakpoint($medium-up) {
          padding-#{$ldirection}: 13px;
        }
      }
      .social-login__email-opt-in {
        margin: 20px 0 10px;
        @include breakpoint($medium-up) {
          margin: 20px 13px 10px;
        }
        padding: 0;
        display: flex;
        .social-login__opt-in-checkbox {
          margin-#{$rdirection}: 10px;
          width: 20px;
          height: 20px;
          padding: 6px;
          @include breakpoint($medium-up) {
            width: auto;
            height: auto;
          }
        }
      }
    }
  }
}
/* Account Profile Page */
#registration {
  .social-info {
    .social-info__content {
      .social-info__connect {
        margin: 20px 0;
      }
      .fb-disclaimer-container {
        margin: 10px 0 10px;
        display: flex;
        #fb-field-disclaimer {
          margin-#{$rdirection}: 10px;
          width: 25px;
          @include breakpoint($medium-up) {
            width: auto;
          }
        }
      }
    }
    .fb-disclaimer-error {
      padding-#{$ldirection}: 13px;
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 25px;
      }
    }
  }
}
