@include breakpoint($portrait-up) {
  #giftcards {
    .egiftcard-container {
      width: 960px;
      margin: 0px auto;
      padding-top: 50px;
      overflow: hidden;
    }
    .error {
      margin-top: 15px;
    }
    .egiftcard-container p {
      padding-bottom: 20px;
      margin-bottom: 0;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 13px;
      line-height: 18px;
    }
    .egiftcard-container-mobile {
      width: 100%;
      margin: 0px auto;
      padding-top: 50px;
      overflow: hidden;
    }
    .other-policies-popup {
      padding: 25px;
    }
    .egiftcard-left-content {
      float: #{$ldirection};
      width: 440px;
      padding-#{$rdirection}: 50px;
    }
    .egiftcard-right-content {
      float: #{$ldirection};
      width: 175px;
    }
    .header-image {
      margin-bottom: 20px;
      font-size: 36px;
      margin-top: 5px;
      font-weight: bold;
      font-family: arial;
    }
    .giftcard-buttons {
      overflow: hidden;
      padding: 0px;
      margin: 0px;
    }
    .giftcard-buttons li a {
      color: #2b2b2b;
      display: inline-block;
      margin-#{$rdirection}: 5px;
      float: #{$ldirection};
    }
    .egift-card-image {
      margin-bottom: 15px;
    }
    .checkbal-rightcontent p {
      padding: 10px 0px;
    }
    .checkbalance-form span {
      font-weight: bold;
    }
    .checkbalance-form {
      margin-bottom: 10px;
    }
    .checkbalance-form input {
      width: 310px;
    }
    .req-fields-txt {
      margin-top: 10px;
    }
    .giftcardbalance-popbox {
      background-color: $color-white;
      padding: 15px;
      font-size: 15px;
      font-weight: bold;
      width: 250px;
    }
    .card-balance-title {
      border-bottom: 1px solid #afadad;
      position: relative;
      top: -2px;
      font-size: 19px;
      padding-bottom: 6px;
      margin-bottom: 10px;
    }
    .giftcardbalance-amtbox {
      text-align: center;
    }
    .overlay-container a.close-link {
      margin-#{$rdirection}: 0px !important;
    }
    .overlay-container .close-container {
      right: 22px;
    }
    .gc_terms_popup h2.title {
      border-bottom: 1px solid #000;
      color: $color-gray-dark;
    }
    .gc_terms_popup {
      background-color: $color-white;
      padding: 35px 20px 20px 20px;
    }
    .overlay-container {
      overflow-y: auto;
      overflow-x: hidden;
    }
    .gc_terms_content {
      padding-top: 15px;
    }
    .gc_terms_content p {
      margin: 0 0 10px 0;
    }
    /* For t&c page */
    #gc_terms p {
      margin: 0 0 10px 0;
    }
  }
}
/* For iPad portrait view */
@include breakpoint($portrait-only) {
  #giftcards {
    .egiftcard-container {
      width: 100%;
      margin: 0px auto;
      padding: 20px;
      overflow: hidden;
    }
    .egiftcard-left-content {
      padding-#{$rdirection}: 25px;
    }
    input.error {
      border: 1px solid #b50404 !important;
    }
  }
}
/* For mobile */
@include breakpoint($med-small-down) {
  #giftcards {
    .egiftcard-container {
      padding: 0px;
      width: 100%;
    }
    .inner-egiftcard-container {
      margin: 10px;
    }
    #giftcard_balance input[type='text'] {
      border-color: #ccc;
      cursor: pointer;
    }
    .checkbalance-submit-mobile {
      background: $color-gray-light;
      color: $color-white;
      border: 0px none;
    }
    #balance_container {
      padding-top: 10px;
      font-weight: bold;
    }
    .header-image {
      font-size: 30px;
      margin-bottom: 10px;
    }
    .egift-card-image {
      text-align: center;
      margin-bottom: 15px;
    }
    .header-image img {
      width: 80%;
    }
    .giftcard-buttons li a {
      text-transform: none;
    }
    .giftcard-buttons li a {
      padding: 5px 5px;
      display: block;
      float: none;
    }
    .card-balance-text {
      color: $color-dark-blue;
      font-size: 20px;
      margin-bottom: 15px;
    }
    #balance {
      font-size: 17px;
    }
    input.error {
      border: 1px solid #b50404 !important;
    }
    .black_button {
      display: inline-block;
      text-align: center;
      width: auto;
      color: $color-white;
      cursor: pointer;
      text-decoration: none;
      font-weight: bold;
      text-transform: uppercase;
      padding: 8px 31px 8px 15px;
      margin: 5px 0px 10px 0px;
      border-width: 0px;
      font-size: 10px;
      background: url(/images/mobile/global/white_right_arrow.png) no-repeat scroll right center #000 !important;
      margin-#{$rdirection}: 0px;
      line-height: 1.5;
      float: right;
    }
    .header_button {
      padding: 4px 31px 4px 15px;
      margin: 9px 15px 0 0;
    }
    .account_header h2.top-heading {
      background-color: #fafafa;
      border-bottom: 2px solid $color-grey-border;
      border-top: 1px solid $color-grey-border;
      clear: none;
      color: $color-dark-blue;
      font-size: 14px;
      padding: 11px 13px 11px;
      text-transform: uppercase;
      width: 100%;
      font-weight: bold;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      line-height: normal;
      margin: 0;
      float: none;
    }
    .pg_wrapper {
      padding: 15px 13px 15px 13px;
      overflow: hidden;
    }
    ul.error_messages {
      color: #a00;
      margin: 5px 0px;
    }
    .error {
      color: $color-dark-red;
    }
    .giftcard .form_element {
      margin: 1em 0;
      clear: both;
      input {
        @include input-placeholder {
          font-size: 12px;
        }
      }
    }
  }
}
