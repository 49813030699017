#registration {
  .account_page {
    #content {
      .profile-form-container {
        .profile {
          .form_element {
            input {
              font-size: 12px;
            }
          }
        }
      }
      .demographic {
        .birthday {
          .form_element {
            select {
              height: 38px;
            }
          }
        }
      }
      .gender_container {
        input ~ label {
          display: inline;
          margin-#{$rdirection}: 10px;
          span.label-content {
            display: block;
          }
        }
        input {
          vertical-align: middle;
        }
      }
      .shipping_subscribe_today {
        .email_promotions {
          margin-top: 3px;
          .label-content {
            margin-#{$ldirection}: 0;
          }
        }
      }
    }
  }
}

#address_book {
  #content {
    #address_form_container {
      margin: 0 15px;
      .sub_form {
        .form-item {
          input {
            &.black_button {
              width: auto;
            }
          }
        }
        .address {
          &.fs {
            #address_form_container {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

#giftcards {
  .check_balance {
    padding: 40% 3% 0 3%;
  }
  .account_page {
    .pg_wrapper {
      padding-#{$ldirection}: 13px;
      padding-#{$rdirection}: 13px;
      #giftcard_balance {
        .value_container {
          input {
            margin-bottom: 1em;
            width: 100%;
          }
        }
        #balance_container {
          padding-top: 10px;
          font-weight: bold;
          .card-balance-text {
            color: $color-dark-blue;
            font-size: 20px;
          }
          #balance {
            font-size: 17px;
            font-weight: bold;
            line-height: 48px;
          }
        }
      }
    }
  }
}

#add_address,
#address_book {
  .account_page {
    #content {
      #address_form_container {
        .form_element {
          select {
            height: 38px;
          }
        }
        .adpl {
          .address {
            &.fs {
              padding: 0;
            }
          }
        }
        .address_form_container_class {
          padding: 0px;
        }
        #QAS_NODE {
          .black_button {
            letter-spacing: 0px;
          }
          .form-item {
            &.spacing {
              padding-right: 0;
              width: 100%;
              .black_button {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }
}

#payment {
  .account_page {
    .payment_address {
      select {
        width: 100%;
      }
    }
    #myaccount-wrapper {
      #payment {
        .payment_method {
          #billing_payment_form {
            .card_type_container {
              display: block;
            }
          }
        }
      }
    }
  }
}

#address_form_container {
  &.adpl {
    padding: 0 13px !important;
  }
  .address.fs {
    .default-shipping-container {
      clear: both;
      margin: 10px 0;
    }
  }
  .form_element,
  .form-item {
    input {
      font-size: 12px;
    }
  }
  .control-btns {
    height: 36px;
    letter-spacing: 2.5px;
  }
}

.fs.email_promotions {
  padding: 0 13px;
  .sms_promo_container {
    input[type='checkbox'] {
      float: left;
      margin-right: 5px;
    }
    input[type='checkbox'] ~ label {
      float: left;
      width: 90%;
    }
  }
}

#address_book {
  .account_page {
    #content {
      #address_form_container {
        .adpl {
          .address {
            padding: 0px 13px;
          }
        }
      }
    }
  }
}

#signin {
  #signin-block {
    .form-item.sms {
      input[type='checkbox'] {
        float: left;
        margin: 2px 5px 0 0;
      }
      label {
        width: 90%;
        display: block;
        span.label-content {
          margin-#{$ldirection}: 0;
        }
        .sms_input {
          width: calc(100% - 45px);
        }
      }
    }
  }
}

#payment_info {
  .account_page {
    #myaccount-wrapper {
      a {
        &.default-payment {
          color: #015289;
          font-weight: bold;
          padding: 0;
          margin: 7px 0 5px 0;
          text-decoration: none;
          text-transform: uppercase;
          width: 100%;
        }
      }
    }
  }
}

#password_reset {
  .password-field {
    &__info {
      @include swap_direction(margin-bottom, 15px);
    }
  }
}

.password-change {
  @include swap_direction(padding, 150px 15px 0);
}
