.welcome15-overlay {
  .site-email-signup {
    &__success {
      @include breakpoint($medium-down) {
        height: 260px;
        width: auto;
        margin: 0 auto;
      }
    }
  }
}
